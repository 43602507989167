:root {
  --primary: #6C1D45;
  --secondary: #893D71;
  --third: #CFA093;
  --fourth: #F3D0A4;
}

.link-qatar-purple {
  color: var(--primary);
  text-decoration: none;
}

.link-qatar-purple:hover,.link-qatar-purple:focus {
  color: var(--secondary);
}

.btn-qatar-purple {
  color: white;
  background-color: var(--primary);
}

.btn-qatar-inverted {
  color: var(--primary);
  background-color: white;
  border-color: var(--primary);
}

.btn-qatar-inverted:hover,
.btn-qatar-inverted:focus,
.btn-qatar-inverted:active,
.btn-qatar-inverted.active,
.open>.dropdown-toggle.btn-qatar-inverted {
  color: var(--secondary);
  background-color: var(--fourth);
  border-color: var(--fourth);
}

.btn-qatar-purple:hover,
.btn-qatar-purple:focus,
.btn-qatar-purple:active,
.btn-qatar-purple.active,
.open>.dropdown-toggle.btn-qatar-purple {
  color: white;
  background-color: var(--secondary);
  ;
  border-color: var(--secondary);
  ;
}

.btn-qatar-success {
  color: var(--primary);
  background-color: var(--fourth);
}

.btn-qatar-success:hover,
.btn-qatar-success:focus,
.btn-qatar-success:active,
.btn-qatar-success.active,
.open>.dropdown-toggle.btn-qatar-success {
  color: white;
  background-color: var(--third);
  ;
  border-color: var(--third);
  ;
}

.no-rounded {
  border-radius: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.img-flag {
  width: 30%;
  border: solid 3px white;
  border-radius: 10%;
}

@media (max-width: 800px) {
  .img-flag {
    width: 12.5%;
  }
}
